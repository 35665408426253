<template>
  <div class="metrics-dashboard">
    <div class="dashboard-link" @click.prevent="openDashboardModal">
      <p class="dashboard-button">
        Open Logs Dashboard View
      </p>
      <font-awesome-icon icon="arrow-up-right-from-square" class="dashboard-icon" />
    </div>
    <!-- Full Screen Modal -->
    <div v-if="showDashboardModal" class="modal-overlay" @click.self="closeDashboardModal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="header-content">
            <h3>{{ dashboardTitle }}</h3>
            <button class="chart-type-toggle" @click="toggleChartType">
              View as {{ chartType === 'area' ? 'Columns' : 'Area' }}
            </button>
          </div>
        </div>
        <div class="modal-body" ref="modalBody">
          <!-- Expanded metric chart -->
          <div v-if="selectedMetricIndex !== null" class="metric-container expanded">
            <div class="metric-actions">
              <button @click="collapseMetric" class="action-button">Collapse</button>
              <button v-if="graphs[selectedMetricIndex].platform_link"
                @click="openLink(graphs[selectedMetricIndex].platform_link)" class="action-button">View in
                Platform</button>
            </div>
            <highcharts-chart
              :options="getMetricChartOptions(graphs[selectedMetricIndex].graph_data, false, selectedMetricOptions)"
              :highcharts="Highcharts" />
          </div>
          <!-- All other graphs-->
          <div class="modal-metrics-content">
            <div v-for="(graph, index) in graphs" :key="'metric-modal-' + index" :class="'metric-container'"
              :style="{ backgroundColor: selectedMetricIndex === index ? 'var(--colors-neutral-gray-700, #2A2F28)' : 'transparent' }">
              <div class="metric-actions">
                <button @click="expandMetric(index)" class="action-button">Expand</button>
                <LogsSummaryStats :logs_summary_stats="graph.logs_summary_stats" />
                <button v-if="graph.platform_link"
                  @click="openLink(graph.platform_link)" class="action-button">View in
                  Platform</button>
              </div>
              <highcharts-chart :options="getMetricChartOptions(graph.graph_data, false, otherMetricOptions)"
                :highcharts="Highcharts" />
            </div>
          </div>
        </div>
        <div class="modal-buttons">
          <PrimaryButton type="standard" @click="closeDashboardModal">Close</PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import { getMetricChartOptions } from '@/utils/chartUtils'
import PrimaryButton from './Buttons/PrimaryButton.vue'
import LogsSummaryStats from './LogsSummaryStats.vue';

function getTimeRangeFromMetrics(logsDashboardArtifact) {
  // Check if logsDashboardArtifact has start and end time
  const metricGroup = logsDashboardArtifact.graphs[0].graph_data
  let minTime = null;
  let maxTime = null;
  // Only look at the first metric group
  metricGroup.metrics.forEach(metric => {
    // Get timestamps from values object
    const timestamps = Object.keys(metric.values);

    timestamps.forEach(timestamp => {
      const time = new Date(timestamp);
      if (!minTime || time < minTime) {
        minTime = time;
      }
      if (!maxTime || time > maxTime) {
        maxTime = time;
      }
    });
  });

  return {
    startTime: minTime?.toISOString(),
    endTime: maxTime?.toISOString()
  };
}

export default {
  name: 'LogsDashboardArtifact',
  components: {
    HighchartsChart: Chart,
    PrimaryButton,
    LogsSummaryStats,
  },
  props: {
    // Take in the full logsDashboardArtifact because we need to look at metrics, jsons, start/endtime
    logsDashboardArtifact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Highcharts,
      showDashboardModal: true,
      selectedMetricIndex: null,
      chartType: 'column',
      tooltippedPoints: {},
      ...getTimeRangeFromMetrics(this.logsDashboardArtifact)
    }
  },
  computed: {
    graphs() {
      return this.logsDashboardArtifact.graphs;
    },
    selectedMetricOptions() {
      return {
        onChartSelection: this.onChartSelection,
        setSyncLineValue: this.setSyncLineValue,
        chartType: this.chartType,
        timeOfInterest: this.logsDashboardArtifact.time_of_interest,
      }
    },
    otherMetricOptions() {
      return {
        startTime: this.startTime,
        endTime: this.endTime,
        setSyncLineValue: this.setSyncLineValue,
        chartType: this.chartType,
        timeOfInterest: this.logsDashboardArtifact.time_of_interest,
      }
    },
    dashboardTitle() {
      const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });
      };
      return `Metrics Dashboard from ${formatDate(this.startTime)} to ${formatDate(this.endTime)}`;
    }
  },
  methods: {
    toggleChartType() {
      this.chartType = this.chartType === 'area' ? 'column' : 'area';
    },
    setSyncLineValue(value) {
      Highcharts.charts.forEach(chart => {
        chart.xAxis[0].removePlotLine('crosshair-line');
        chart.xAxis[0].addPlotLine({
          value: value,
          color: 'gray',
          width: 1,
          id: 'crosshair-line',
        });
      });
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    onChartSelection(event) {
      if (event.resetSelection) {
        const { startTime, endTime } = getTimeRangeFromMetrics(this.logsDashboardArtifact)
        this.startTime = startTime;
        this.endTime = endTime;
        return;
      }

      if (!event || !event.xAxis) return;

      const [xAxis] = event.xAxis;
      this.startTime = new Date(xAxis.min).toISOString();
      this.endTime = new Date(xAxis.max).toISOString();
    },
    getMetricChartOptions,
    openDashboardModal() {
      this.showDashboardModal = true
      window.addEventListener('keyup', this.onKeyUp)
    },
    closeDashboardModal() {
      this.showDashboardModal = false
      this.selectedMetricIndex = null
      window.removeEventListener('keyup', this.onKeyUp)
    },
    onKeyUp(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeDashboardModal()
      }
    },
    expandMetric(index) {
      this.selectedMetricIndex = index
      // Scroll to top of the metric container using ref
      if (this.$refs.modalBody) {
        this.$refs.modalBody.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    collapseMetric() {
      this.selectedMetricIndex = null
    }
  }
}
</script>

<style scoped>
.metric-container.expanded {
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.summary-hover {
  position: relative;
  cursor: pointer;
}

.summary-hover:hover .summary-popup {
  display: block;
}

.summary-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 966px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  padding-bottom: 0px;
}

.chart-type-toggle {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: var(--colors-neutral-gray-600, #DBDCDA);
  border: 1px solid var(--colors-neutral-gray-600, #3D423B);
  transition: all 0.2s ease;
}

.chart-type-toggle:hover {
  background-color: var(--colors-neutral-gray-600, #3D423B);
}

.metric-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.metric-actions button {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: var(--colors-neutral-gray-600, #DBDCDA);
  border: 1px solid var(--colors-neutral-gray-600, #3D423B);
  transition: all 0.2s ease;
}

.metric-actions button:hover {
  background-color: var(--colors-neutral-gray-600, #3D423B);
}

.metric-actions button:active {
  transform: translateY(1px);
}

.metrics-dashboard {
  gap: 20px;
}

.metric-container {
  min-height: 300px;
  border-radius: 8px;
  padding-right: 12px;
}

.dashboard-link {
  border-radius: 8px;
  border: 2px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.dashboard-button {
  border-radius: 6px 6px 0px 0px;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: var(--colors-white, #FFF);
  width: 100%;
  text-align: center;
  padding: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.dashboard-icon {
  padding: 16px;
  padding-bottom: 14px;
  font-size: 22px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--colors-neutral-gray-800, #0C160B);
  border-radius: 12px;
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.modal-header h3 {
  color: var(--colors-white, #FFF);
  font-family: Inter, sans-serif;
  font-size: 16px;
  margin: 0;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.modal-metrics-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

/* Scrollbar Styles */
.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: var(--colors-neutral-gray-800, #0C160B);
}
</style>
