<template>
  <div class="preview-card" @click="handleClick" :class="{ highlighted: isHighlighted }">
    <!-- minibutton that links to artifact url iff there is one, stickied to the right-->
    <div class="inline-exit">
      <template v-if="artifact.urls && artifact.urls.length > 0">
        <MiniButton
          v-for="(url, index) in artifact.urls.slice(0, 3)"
          :key="index"
          type="standard"
          icon="exitarrow"
          @click="openArtifactUrl(url)"
          class="exit-button"
        />
      </template>
    </div>

    <div v-if="showToolInfo" class="tool-info">
      <CitationCounter
        v-if="count >= 1"
        :count="count"
        :previewCardId="artifact.tool_call_id"
        @open-preview-card="handleClick"
        class="inline-counter"
      />
      <span class="tool-name">{{ toolName }}</span>
    </div>
    <div class="preview-content">
      <!-- Error Preview -->
      <div v-if="thumbnailType === 'error'" class="preview-error">
        <div class="error-message">{{ thumbnailContent }}</div>
      </div>
      
      <!-- Metric Preview -->
      <div v-if="thumbnailType === 'metric'" class="preview-metric">
        <highcharts-chart
          :options="metricChartOptions"
          :highcharts="Highcharts"
          style="width: 100%; height: 100%;"
        />
      </div>
      <!-- Image Preview -->
      <img v-else-if="thumbnailType === 'image'" :src="thumbnailContent" class="preview-image" />
      <!-- URL Preview -->
      <div v-else-if="thumbnailType === 'url'" class="preview-url">
        <img src="@/assets/link_icon.svg" class="link-icon" />
      </div>

      <!-- Code Preview -->
      <div v-else-if="thumbnailType === 'code'" class="preview-code">
        <CodeBlock :code="thumbnailContent.code" :language="thumbnailContent.language" theme="everforest-dark" />
      </div>

      <!-- Terminal Output Preview -->
      <div v-else-if="thumbnailType === 'terminal_output'" class="preview-terminal">
        <CodeBlock :code="thumbnailContent" language="shellsession" theme="everforest-dark" />
      </div>


      <!-- Logs Preview -->
      <div v-else-if="thumbnailType === 'log'" class="preview-logs">
        <div class="logs-preview-container">
          <vue-json-pretty
            :data="thumbnailContent"
            :item-height="8"
            :deep="3"
            :theme="'dark'"
            :show-icon="false" 
            :show-line="false" 
            :show-line-number="false"
            class="logs-json-pretty"
          />
        </div>
      </div>

      <!-- Jsons Preview -->
      <div v-else-if="thumbnailType === 'json'" class="preview-logs">
        <div class="logs-preview-container">
          <vue-json-pretty
            :data="thumbnailContent"
            :item-height="8"
            :deep="1"
            :theme="'dark'"
            :show-icon="false" 
            :show-line="false" 
            :show-line-number="false"
            class="logs-json-pretty"
          />
        </div>
      </div>

      <!-- Text Preview -->
      <div v-else-if="thumbnailType === 'text'" class="preview-text">
        <div class="text-preview-content">
          <vue-json-pretty
            :data="thumbnailContent"
            :item-height="8"
            :theme="'dark'"
            :show-icon="false" 
            :show-line="true" 
            :show-line-number="true"
            class="logs-json-pretty"
          />

        </div>
      </div>
      <!-- Dataframe Preview -->
      <div v-else-if="thumbnailType === 'dataframe'" class="preview-dataframe">
        <table class="dataframe-preview-table">
          <thead>
            <tr>
              <th v-for="(values, columnName) in thumbnailContent" :key="columnName">{{ columnName }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rowIndex in previewRowCount" :key="rowIndex">
              <td v-for="(values, columnName) in thumbnailContent" :key="columnName">
                {{ values[rowIndex] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import CitationCounter from './CitationCounter.vue';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { getMetricChartOptions } from '@/utils/chartUtils';
import MiniButton from './Buttons/MiniButton.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import CodeBlock from './CodeBlock.vue';

export default {
  name: 'PreviewCard',
  components: {
    HighchartsChart: Chart,
    CitationCounter,
    MiniButton,
    VueJsonPretty,
    CodeBlock,
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    artifact: {
      type: Object,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: true,
    },
    showToolInfo: {
      type: Boolean,
      default: false, // Show by default
    },
  },
  data() {
    return {
      Highcharts,
    };
  },
  computed: {
      toolName() {
        const name = this.artifact.tool_name || '';
        // Trim the string to remove spaces, newlines, and carriage returns
        const trimmedName = name.trim();
        // If it's an empty string after trimming, default to "Find Anomalies"
        return trimmedName === '' ? 'Find Anomalies' : trimmedName;
      },
      truncatedText() {
        const maxLength = 200; // Adjust as needed
        const textContent = this.thumbnailContent;
        return textContent.length > maxLength ? textContent.substring(0, maxLength) + '...' : textContent;
      },
      previewRowCount() {
        if (this.thumbnailType === 'dataframe' && this.thumbnailContent) {
          const firstColumn = Object.values(this.thumbnailContent)[0];
          return Array.from({ length: Math.min(firstColumn.length, 5) }, (_, i) => i); // Limit to 5 rows
        }
        return [];
      },
      thumbnailType() {
        if (this.artifact.error) {
          return 'error';
        } else if (this.artifact.metrics && this.artifact.metrics.length > 0) {
          return 'metric';
        } else if (this.artifact.images && this.artifact.images.length > 0) {
          return 'image';
        } else if (this.artifact.dataframes && this.artifact.dataframes.length > 0) {
          return 'dataframe';
        } else if (this.artifact.code && this.artifact.code.length > 0) {
          return 'code';
        } else if (this.artifact.terminal_output && this.artifact.terminal_output.length > 0) {
          return 'terminal_output';
        } else if (this.artifact.logs && this.artifact.logs.length > 0) {
          return 'log';
        } else if (this.artifact.texts && this.artifact.texts.length > 0) {
          return 'text';
        } else if (this.artifact.jsons && this.artifact.jsons.length > 0) {
          return 'json';
        } else if (this.artifact.urls && this.artifact.urls.length > 0) {
          return 'url';
        }
        return 'unknown';
      },
      thumbnailContent() {
        switch (this.thumbnailType) {
          case 'error':
            return this.artifact.error;
          case 'image':
            return `data:image/png;base64,${this.artifact.images[0].image_data}`;
          case 'dataframe':
            return this.artifact.dataframes[0];
          case 'code':
            return this.artifact.code;
          case 'terminal_output':
            return this.artifact.terminal_output;
          case 'log':
            return this.artifact.logs[0];
          case 'text':
            // join texts with new line
            return this.artifact.texts;
          case 'json':
            return this.artifact.jsons[0];
          case 'url':
            return this.artifact.urls[0];
          default:
            return null;
        }
      },
      metricChartOptions() {
        if (this.thumbnailType === 'metric') {
          // For simplicity, we'll use the first metric
          const metricQuery = this.artifact.metrics[0];
          return getMetricChartOptions(metricQuery, true); // Pass true for preview
        }
        return null;
      },
  },
  methods: {
    handleClick() {
      // Emit the entire artifact
      this.$emit('open-preview-modal', this.artifact);
    },
    highlightCard() {
      this.$emit('highlight-preview-card', this.artifact.tool_call_id);
    },
    removeHighlightCard() {
      this.$emit('remove-highlight-preview-card', this.artifact.tool_call_id);
    },
    openArtifactUrl(url) {
      window.open(url, '_blank');
      //window.open(this.artifact.urls[0], '_blank');
    },

  },
};
</script>

<style scoped>
.preview-card {
  width: 250px;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  margin: 8px;
  cursor: pointer;
  background-color: var(--colors-neutral-gray-50, #161F15);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--colors-neutral-gray-100, #353E34);
  position: relative;

}

.inline-counter {
  margin-left: 8px; /* Add spacing between the counter and tool name */
}

.inline-exit {
  position: absolute; /* Positions it over the content */
  top: 5px;           /* Adjust the spacing from the top */
  right: 8px;         /* Adjust the spacing from the right */
  margin: 0;          /* Remove any margins */
  z-index: 1;         /* Ensure it's above the content */
}

.exit-button {
  height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.tool-info {
  display: flex;
  align-items: center; /* Ensure counter and tool name are vertically aligned */
  width: 100%;
  padding: 8px 16px;
  background: #112110;
  text-align: left;
  border-bottom: 1px solid var(--colors-neutral-gray-100, #353E34);
}

.preview-card.highlighted .tool-info {
  background: var(--colors-green-green-100-alpha, rgba(64, 133, 62, 0.35));
}

.preview-card.highlighted .preview-content {
  background: var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.preview-card.highlighted .inline-counter {
  background-color: var(--colors-green-green-50, #162C15);
  color: var(--colors-green-green-600, #669D65);

}


/* Add this CSS rule within your <style scoped> tag */
.preview-card:hover .tool-info {
  background: var(--colors-green-green-100-alpha, rgba(64, 133, 62, 0.35));
}

.preview-card:hover .preview-content {
  background: var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));

}


.preview-content {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Metric Preview */
.preview-metric {
  width: 100%;
  height: 100%;
}


.preview-url {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colors-white, #FFF);
  text-align: center;
  padding: 8px;
}

.preview-text {
  padding: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.text-preview-content {
  margin-top: 8px;
  font-size: 10px;
  line-height: 12px;
  color: var(--colors-white, #FFF);
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-dataframe {
  width: 100%;
  overflow: hidden;
}

.dataframe-preview-table {
  width: 100%;
  font-size: 10px;
  color: var(--colors-white, #FFF);
  border-collapse: collapse;
}

.dataframe-preview-table th,
.dataframe-preview-table td {
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  padding: 2px;
  text-align: left;
}

.preview-code,
.preview-terminal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #2D353B;
}


.tool-name {
  color: var(--colors-green-green-600, #669D65);
  /* text-base/semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-left: 8px;
}

.logs-preview-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logs-json-pretty {
  font-size: 6px; /* Smaller font for preview */
  overflow: hidden;
}

.error-message {
  color: var(--colors-red-red-600, #A61B1B);
  padding: 8px;
  wrap: break-word;

}

</style>
